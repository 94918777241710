@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
  }

  /* container */
  .nike-container {
    @apply w-11/12 m-auto;
  }

  /* Scroll */
  .scroll-hidden::-webkit-scrollbar {
    display: none;
  }
  .scroll-style::-webkit-scrollbar {
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: rgb(66, 245, 141) rgb(66, 245, 141);
  }
  .scroll-style::-webkit-scrollbar {
    @apply w-2;
  }
  .scroll-style::-webkit-scrollbar-track {
    @apply bg-slate-700;
  }
  .scroll-style::-webkit-scrollbar-thumb {
    @apply rounded-full bg-gradient-to-tr from-blue-500 to-green-500 via-teal-500;
  }

  /* Theme-Colors */
  .bg-theme {
    background-image: linear-gradient(
      0.2deg,
      rgba(40, 173, 222, 1) 4.8%,
      rgb(27, 76, 224) 85.5%
    );
  }
  .bg-theme-cart {
    @apply bg-gradient-to-b from-slate-900 to-gray-900 shadow-sm shadow-gray-900;
  }

  /* Clip-path */
  .clip-path {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 65%);
  }

  /* Blur-Effect */
  .blur-effect-theme {
    @apply bg-white/50 bg-clip-padding backdrop-filter backdrop-blur bg-opacity-50 opacity-100 z-[100] shadow-sm shadow-slate-100 transition-all duration-300;
  }

  /* Icon Stles */
  .icon-style {
    @apply h-6 w-6 sm:h-5 sm:w-5 text-slate-100 cursor-pointer transition-all duration-100 active:scale-110 leading-tight;
  }

  /* text-gradient */
  .text-gradient {
    @apply text-transparent bg-clip-text bg-gradient-to-tr from-blue-500 to-sky-500;
  }

  /* border */
  .rounded-theme {
    border-radius: 5% 100% 10% 5%;
  }

  /* button */
  .button-theme {
    @apply px-7 py-1 rounded active:scale-90 transition-all duration-100 ease-in-out shadow-md sm:text-sm;
  }

  /* transitions-theme */
  .transitions-theme {
    @apply transition-all duration-700 ease-in-out cursor-pointer;
  }
}


.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

